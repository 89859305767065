<template>
	<div class="container">
		<el-form :model="form" :rules="rules" ref="form" label-position="top" :hide-required-asterisk="true" class="form">
		    <div class="label">Deposit amount to your account</div>
		    <el-row :gutter="10">
				<el-col :span="24">
				    <el-form-item label="Select deposit amount">
				        <!-- <el-select v-model="form.num" placeholder="" @change="changeNum">
				            <el-option v-for="(item,index) in numList" :key="index" :label="item.label" :value="item.num"></el-option>
				        </el-select> -->
						<el-row :gutter="10">
							<el-col v-for="(item,index) in numList" :key="index" :xs="8" :sm="6" :md="6">
								<div :class="['num-item', { 'active': numIndex == index }]" @click="changeNumItem(index,item)">
									{{item.label}}
								</div>
							</el-col>
						</el-row>
				    </el-form-item>
				</el-col>
				<el-col :span="24">
				    <el-form-item label="Method of payment">
				        <img class="pay-img" src="@/assets/images/index/PayPal.png" />
				    </el-form-item>
				</el-col>
		        <el-col :span="24">
		            <el-form-item label="Deposit bonus percentage">
		                <el-input :value="percent" disabled placeholder="Please select a deposit amount first">
							 <template slot="append">%</template>
						</el-input>
		            </el-form-item>
		        </el-col>
				<el-col :span="24">
				    <el-form-item label="Actual amount received">
				        <el-input :value="actualAmount" disabled placeholder="Please select a deposit amount first">
							<template slot="prepend">{{$currencySymbol}}</template>
						</el-input>
				    </el-form-item>
				</el-col>
		        <el-col :span="24">
		            <el-form-item>
		                <div class="operate">
		                    <div class="back" @click="back">
		                        <i class="el-icon-arrow-left el-icon--left"></i>
		                        Back to change
		                    </div>
		                    <div class="submit-btn" @click="submitForm">Confirm Deposit</div>
		                </div>
		            </el-form-item>
		        </el-col>
		    </el-row>
		</el-form>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				form: {
				    num: ''
				},
				numList: [],
				numIndex: -1,
				rules: {
				    // num: [
				    //     {
				    //         required: true,
				    //         message: 'Please select a deposit amount',
				    //         trigger: 'blur'
				    //     }
				    // ]
				},
				percent: ''
			}
		},
        created() {
            this.getNumList()
        },
		computed: {
			actualAmount() {
				let num = this.form.num ? parseFloat(this.form.num) : 0
				let percent = this.percent ? parseFloat(this.percent) : 0
				let other_num = parseFloat(num * (percent / 100)).toFixed(2)
				return parseFloat((num + parseFloat(other_num)).toFixed(2)) || ''
			}
		},
		methods: {
			getNumList() {
				this.$api.setting({
				    slug: 'recharge_list'
				},false,600000).then(res => {
				    this.numList = res.data ? JSON.parse(res.data) : []
				})
			},
			
			changeNum(num) {
				// console.log(num)
				this.form.num = num ? Number(num) : ''
				const find_item = this.numList.find(item => item.num == num)
				this.percent = find_item ? parseFloat(find_item.rate) : ''
			},
			
			changeNumItem(index,item) {
				if(this.numIndex != index) {
					this.numIndex = index
					this.form.num = item.num
					this.percent = item.rate ? parseFloat(item.rate) : ''
				}
			},
			
			submitForm() {
			    this.$refs.form.validate((valid) => {
			        if(valid) {
                        if(!this.form.num) {
							this.$message({
							    message: 'Please select a deposit amount',
							    type: 'warning'
							})
							return
						} else {
							this.$api.recharge({
							    index: this.numIndex,
							},true).then(res => {
								if(res.data?.url) {
									location.href = res.data.url
								}
							})
						}
			        }
			    })
			},
			
			back() {
			    this.$router.go(-1)
			}
		}
	}
</script>

<style lang="scss" scoped>
    // PC
    @media only screen and (min-width: 1024px) {
        .container {
        	width: 100%;
        	min-height: 100vh;
        	box-sizing: border-box;
        	padding: 5rem 0 4rem;
        	
        	.form {
        	    width: 60rem;
        		margin: 0 auto;
        	    
        	    .label {
        	        width: 100%;
        	        margin-bottom: 3rem;
        	        font-size: 2.4rem;
					font-weight: bold;
        	        color: #333333;
        	        word-wrap: break-word;
        	    }
        	    
        	    /deep/ .el-form-item {
        	        margin-bottom: 3rem;
        	    }
				
				.num-item {
					display: flex;
					justify-content: center;
					align-items: center;
					width: 100%;
					height: 4rem;
					box-sizing: border-box;
					border: 1px solid #d9d9d9;
					border-radius: 4px;
					font-size: 1.4rem;
					color: #333333;
					white-space: nowrap;
					background-color: #FFFFFF;
					cursor: pointer;
				}
				
				.active {
					color: #21a4ea;
					border-color: #21a4ea;
				}
				
				.pay-img {
					display: block;
					width: 20rem;
				}
        	    
        	    .operate {
        	        display: flex;
        	        justify-content: space-between;
        	        align-items: center;
        	        width: 100%;
        	        margin-top: 1rem;
        	        
        	        .back {
        	            font-size: 1.4rem;
        	            color: #333333;
        	            white-space: nowrap;
        	            cursor: pointer;
        	        }
        	        
        	        .submit-btn {
        	            min-width: 12rem;
        	            height: 6rem;
        	            line-height: 6rem;
        	            box-sizing: border-box;
        	            padding: 0 2.5rem;
        	            border-radius: 4px;
        	            font-size: 1.5rem;
        	            color: #FFFFFF;
        	            text-align: center;
        	            white-space: nowrap;
        	            background-color: #000000;
        	            cursor: pointer;
        	        }
        	    }
        	}
        }
    }
    
    // 手机
    @media only screen and (max-width: 1024px) {
        .container {
        	width: 100%;
        	min-height: 100vh;
        	box-sizing: border-box;
        	padding: 2.5rem $h5-box-padding;
        	
        	.form {
                width: 100%;
        	    
        	    .label {
        	        width: 100%;
        	        margin-bottom: 3rem;
        	        font-size: 2rem;
					font-weight: bold;
        	        color: #333333;
        	        word-wrap: break-word;
        	    }
        	    
        	    /deep/ .el-form-item {
        	        margin-bottom: 3rem;
        	    }
				
				.num-item {
					display: flex;
					justify-content: center;
					align-items: center;
					width: 100%;
					height: 4rem;
					box-sizing: border-box;
					border: 1px solid #d9d9d9;
					border-radius: 4px;
					font-size: 1.4rem;
					color: #333333;
					white-space: nowrap;
					background-color: #FFFFFF;
					cursor: pointer;
				}
				
				.active {
					color: #21a4ea;
					border-color: #21a4ea;
				}
				
				.pay-img {
					display: block;
					width: 18rem;
				}
        	    
        	    .operate {
        	        display: flex;
        	        flex-direction: column-reverse;
        	        align-items: center;
        	        width: 100%;
        	        margin-top: 1rem;
        	        
        	        .back {
        	            margin-top: 1rem;
        	            font-size: 1.4rem;
        	            color: #333333;
        	            white-space: nowrap;
        	            cursor: pointer;
        	        }
        	        
        	        .submit-btn {
        	            width: 100%;
        	            height: 5.5rem;
        	            line-height: 5.5rem;
        	            box-sizing: border-box;
        	            padding: 0 2rem;
        	            border-radius: 4px;
        	            font-size: 1.5rem;
        	            color: #FFFFFF;
        	            text-align: center;
        	            white-space: nowrap;
        	            background-color: #000000;
        	            cursor: pointer;
        	        }
        	    }
        	}
        }
    }
</style>